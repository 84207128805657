<template>
  <div class="max-w-7xl m-auto grid grid-cols-12 grid-rows-1 p-8 px-5 sm:p-8">
    <div
      class="col-span-12 lg:col-span-8 xl:col-span-6 mx-0 mb-1 mt-0 sm:mx-4 sm:mb-4 m-4 sm:mt-6"
    >
      <h1
        class="uppercase text-brand-purple-500 font-serif font-bold text-2xl sm:text-3xl"
      >
        <n-html :text="$t('report.damage.title')" />
      </h1>
      <div class="mt-6" v-if="accepted">
        <div v-if="!storeProvided">
          <p class="text-brand-gray-500">
            <n-html :text="$t('report.damage.success.select_store')" /> <br />
            <n-html
              class="text-brand-gray-400"
              :text="$t('report.damage.success.select_store_instruction')"
            />
          </p>
          <div class="mb-4">
            <n-select-search
              :oninput="onStoreChanged"
              :onsearch="onStoreSearch"
              :options="storeOptions"
              :filterable="false"
            />
            <div class="mt-2" v-if="selectedStoreObject">
              <n-card>
                <ul class="list-none list-inside">
                  <li>
                    <n-html :text="$t('claims.store.store_name')" />
                    : {{ selectedStoreObject.label }}
                  </li>
                  <li>
                    <n-html :text="$t('claims.store.store_address')" />
                    : {{ selectedStoreObject.address }}
                  </li>
                  <li>
                    <n-html :text="$t('claims.store.store_zipcode')" />
                    : {{ selectedStoreObject.zipcode }}
                  </li>
                  <li>
                    <n-html :text="$t('claims.store.store_city')" />
                    : {{ selectedStoreObject.city }}
                  </li>
                  <li>
                    <n-html :text="$t('claims.store.store_email')" />
                    : {{ selectedStoreObject.email }}
                  </li>
                  <li>
                    <n-html :text="$t('claims.store.store_phone')" />
                    : {{ selectedStoreObject.phone }}
                  </li>
                </ul>
              </n-card>
            </div>
          </div>

          <n-button-regular
            @click.native="updateClaimWithStore"
            class="margin-bottom-grid-between"
            :disabled="!selectedStore || storeLoading"
          >
            <n-html :text="$t('report.damage.success.select_store_button')" />
          </n-button-regular>
        </div>
        <div v-else>
          <p class="text-brand-gray-500">
            <n-html :text="$t('report.damage.success.number')" />
            <strong> {{ this.claim.navision_ext_id }}</strong>
          </p>
          <p class="text-brand-gray-500">
            <n-html :text="this.claim.customer_notification" />
          </p>
          <div v-if="storeProvided">
            <div v-if="store.address">
              <p class="text-brand-gray-500">
                <n-html :text="$t('report.damage.success.store_found')" />
                <br />
              </p>
              <p class="text-brand-gray-500 mb-4">
                {{ store.name }} <br />
                {{ store.address }} <br />
                {{ store.zipcode }} {{ store.city }} <br />
                {{ store.phone }}
              </p>
            </div>

            <p v-else-if="storeNotFound" class="text-brand-gray-500">
              <n-html :text="$t('report.damage.success.store_not_found')" />
            </p>
          </div>
          <n-button-regular
            @click.native="
              $router.push({ name: 'claims.index' }).catch(() => {})
            "
            class="margin-bottom-grid-between"
          >
            <n-html :text="$t('report.common.button_to_claim')" />
          </n-button-regular>
        </div>
      </div>
      <div class="mt-6" v-else-if="rejected">
        <p class="text-brand-gray-500">
          <n-html :text="this.claim.customer_notification" />
        </p>
        <n-button-regular
          @click.native="$router.push({ name: 'claims.index' }).catch(() => {})"
          class="margin-bottom-grid-between"
        >
          <n-html :text="$t('report.common.button_to_claim')" />
        </n-button-regular>
      </div>
    </div>
    <div
      class="col-span-12 lg:col-span-6 mx-0 m-4 sm:mx-4 mt-8"
      v-if="form_states.form"
    >
      <n-card no-margin>
        <h3 class="text-brand-purple-500 font-serif font-semibold text-xl">
          <n-html :text="$t('report.damage.success.continue.title')" />
        </h3>
        <p class="text-brand-gray-500 mt-6">
          <n-html :text="$t('report.damage.success.continue.subtitle')" />
        </p>
        <ol class="bg-brand-purple-100/25 p-6 mt-6">
          <li
            v-for="(step, index) in continueSteps"
            v-bind:key="step"
            class="flex items-center mb-4"
          >
            <p class="pr-5 text-brand-purple-500">{{ index + 1 }}</p>
            <p>
              <n-html :text="$t(step)" />
            </p>
          </li>
        </ol>
        <p class="text-brand-gray-500 mt-6">
          <n-html :text="$t('report.damage.success.continue.additional')" />
        </p>
        <n-button-link
          bold
          @click.native="
            $router.push({ name: 'service.index' }).catch(() => {})
          "
          class="flex items-center mt-6"
        >
          <n-html :text="$t('report.common.link_to_customer_service')" />
          <n-icon name="Chevron-right" small></n-icon>
        </n-button-link>
      </n-card>
    </div>

    <!--      This should be an error-->
    <div class="col-span-12" v-if="!this.contracts.length">
      <n-html :text="$t('report.common.no_contracts')" />
    </div>

    <div
      class="col-span-12 mx-0 mt-0 sm:mx-4 m-4 sm:mt-6"
      v-if="!form_states.form && this.contracts.length"
    >
      <card-step
        ref="step1"
        step="1"
        :title="$t('report.common.personal_information.title')"
        @dataChange="d => (this.step1 = d)"
      >
        <StepPersonalInformation
          ref="step1"
          @dataChange="d => (this.step1 = d)"
        />
      </card-step>

      <card-step
        ref="step2"
        step="2"
        :title="$t('report.common.product.title')"
        @dataChange="
          d => {
            this.step2 = d;
            this.step3 = {};
          }
        "
      >
        <StepDevice claim-type="damage" />
      </card-step>
      <card-step
        ref="step3"
        step="3"
        :title="$t('report.damage.type.title')"
        @dataChange="d => (this.step3 = d)"
      >
        <StepType claim-type="damage" :contract="this.step2.contract" />
      </card-step>
      <card-step
        ref="step4"
        step="4"
        manual_close
        :title="$t('report.common.date.title')"
        @dataChange="d => (this.step4 = d)"
      >
        <StepDate claim-type="damage" :contract="this.step2.contract" />
      </card-step>
      <card-step
        ref="step5"
        step="5"
        manual_close
        :title="$t('report.damage.area.title')"
        @dataChange="d => (this.step5 = d)"
      >
        <StepArea
          :contract="this.step2.contract"
          :type="this.step3.type"
          claim-type="damage"
        />
      </card-step>
      <card-step
        ref="step6"
        step="6"
        :title="$t('report.damage.description.title')"
        @dataChange="d => (this.step6 = d)"
      >
        <StepDescription />
      </card-step>
      <card-step
        ref="step7"
        step="7"
        :title="$t('report.damage.reclaim.title')"
        @dataChange="d => (this.step7 = d)"
      >
        <StepQuestion :subtitle="$t('report.damage.reclaim.subtitle')" />
      </card-step>
      <n-form-group
        id="button-group-claim-create"
        class="mt-4"
        :invalid-feedback="$t('report.damage.create.error')"
        :state="form_states.form"
      >
        <n-button-regular
          class="float-right w-full sm:w-max"
          @click.native="show_confirm_modal = true"
          :disabled="!valid_confirm || form_states.loading"
        >
          <n-html :text="$t('report.damage.confirm.button')" />
        </n-button-regular>
      </n-form-group>
    </div>

    <!--    MODAL-->
    <modal
      v-if="show_confirm_modal"
      @close="show_confirm_modal = false"
      :title="$t('report.damage.confirm.title')"
    >
      <template>
        <p class="py-5 border-b">
          <n-html :text="$t('report.damage.confirm.description')" />
        </p>
        <ConfirmStepInfo
          :title="$t('report.common.product.title')"
          step="step2"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step2.picked"
          type="damage"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.damage.type.title')"
          step="step3"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step3.picked"
          type="damage"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.common.date.title')"
          step="step4"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step4.picked"
          type="damage"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.damage.area.title')"
          step="step5"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step5.picked"
          type="damage"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.damage.description.title')"
          step="step6"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :values="step6"
          type="damage"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.damage.reclaim.title')"
          step="step7"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step7.picked"
          type="damage"
        ></ConfirmStepInfo>
        <p class="my-5">
          <n-form-group id="input-required-question" class="margin-bottom-none">
            <n-checkbox id="input-easyregister-agreement" v-model="step8">
              <n-html :text="$t('report.damage.truth.subtitle')" />
            </n-checkbox>
          </n-form-group>
        </p>
      </template>
      <template v-slot:footer>
        <div />
        <n-button-regular
          @click.native="save"
          :disabled="!valid_send || form_states.loading"
        >
          <n-html :text="$t('report.damage.create.button')" />
        </n-button-regular>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import CardStep from "../../components/report/card-step";
import StepPersonalInformation from "../../components/report/step-personal-information";
import StepDevice from "../../components/report/step-device";
// import StepType from "../../components/report/damage/step-type";
import StepType from "../../components/report/step-type";
import StepDate from "../../components/report/step-date";
// import StepArea from "../../components/report/damage/step-area";
import StepArea from "../../components/report/step-area";
import StepDescription from "../../components/report/damage/step-description";
import StepQuestion from "../../components/report/step-required-question";
import Modal from "@/components/theme/modal/modal";
import ConfirmStepInfo from "@/components/report/confirm-step-info";
import ClaimConfiguration from "@/assets/json/claim_configuration.json";
import { uuid } from "vue-uuid";

export default {
  name: "report.damage",
  components: {
    Modal,
    CardStep,
    StepPersonalInformation,
    StepDevice,
    StepType,
    StepDate,
    StepArea,
    StepDescription,
    StepQuestion,
    ConfirmStepInfo
  },
  data: () => ({
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: false,
    show_confirm_modal: false,
    form_states: {
      form: null,
      loading: false
    },
    claim_number: null,
    routed: false,
    storeProvided: false,
    storeNotFound: true,
    // Set accpeted to true for show suppliers select
    accepted: false,
    rejected: false,
    selectedStore: null,
    selectedStoreObject: null,
    current_latitude: null,
    current_longitude: null,
    storeOptions: [],
    storeOptionsBackup: [],
    resultsFoundByZipCode: false,
    storeLoading: false,
    continueSteps: []
  }),
  computed: {
    ...mapGetters({
      contracts: "contracts/getAllActive"
    }),
    claim() {
      return this.$store.getters["claims/getClaim"];
    },
    store() {
      return this.$store.getters["claims/getStore"];
    },
    stores() {
      return this.$store.getters["claims/getStores"];
    },
    showStep1() {
      return _.isEmpty(this.step1);
    },
    showStep2() {
      return !_.isEmpty(this.step1) && _.isEmpty(this.step2);
    },
    showStep3() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        _.isEmpty(this.step3)
      );
    },
    showStep4() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        _.isEmpty(this.step4)
      );
    },
    showStep5() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        _.isEmpty(this.step5)
      );
    },
    showStep6() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        !_.isEmpty(this.step5) &&
        _.isEmpty(this.step6)
      );
    },
    showStep7() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        _.isEmpty(this.step7)
      );
    },
    valid_send() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        !_.isEmpty(this.step7) &&
        this.step8
      );
    },
    valid_confirm() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        !_.isEmpty(this.step7)
      );
    }
  },
  watch: {
    showStep1(n) {
      this.$refs.step1.setCollapsed(!n);
    },
    showStep2(n) {
      this.$refs.step2.setCollapsed(!n);
    },
    showStep3(n) {
      this.$refs.step3.setCollapsed(!n);
    },
    showStep4(n) {
      this.$refs.step4.setCollapsed(!n);
    },
    showStep5(n) {
      this.$refs.step5.setCollapsed(!n);
    },
    showStep6(n) {
      if (!n && !this.showStep5 && !_.isEmpty(this.step6)) {
        return;
      }
      this.$refs.step6.setCollapsed(!n);
    },
    showStep7(n) {
      this.$refs.step7.setCollapsed(!n);
    },
    claim() {
      this.determineNextStep();
    },
    step2() {
      const _category = ClaimConfiguration.objectCategories.find(
        objectCategory =>
          objectCategory.name === this.step2.contract.category_code
      );

      this.continueSteps = _category.nextSteps["damage"];
    }
  },
  methods: {
    onStoreChanged(value) {
      this.selectedStore = value;
      this.storeOptionsBackup.forEach(s => {
        if (s && s.code === value) {
          this.selectedStoreObject = s;
        }
      });
    },
    onStoreSearch(value) {
      if (!value) {
        this.storeOptions = this.storeOptionsBackup;
        return;
      }

      const zipCodeRegex = /^(\d{4}\s?[A-Za-z]{2}|\d{5}\s?[A-Za-z]{2})$/;

      if (zipCodeRegex.test(value)) {
        this.getStores(value);
      } else if (value.length >= 3) {
        // Search by city
        this.getStores("", value);
        // let tempStoreOptions = [];
        // this.storeOptions.map(store => {
        //   console.log(store);
        //   if (
        //     store.city.toLowerCase().includes(value.toLowerCase()) ||
        //     store.label.toLowerCase().includes(value.toLowerCase())
        //   ) {
        //     const filtered = tempStoreOptions.filter(
        //       s => s.code === store.code
        //     );
        //     if (filtered.length === 0) {
        //       tempStoreOptions.push(store);
        //     }
        //   }
        // });
        // this.storeOptions = tempStoreOptions;
      } else {
        this.storeOptions = this.storeOptionsBackup;
      }
    },
    getStore() {
      return this.$store
        .dispatch("claims/getStore", this.claim.store_no)
        .then(() => console.log(this.store))
        .catch(() => (this.storeNotFound = true));
    },
    getStores(zipcode, city) {
      this.$store
        .dispatch("claims/getStores", {
          template: this.claim.contract.template_no_claim,
          // template: "template_no_claim",
          zipcode: zipcode,
          city: city
        })
        .then(() => {
          this.storeOptions = this.stores.suppliers.map(item => {
            if (item.is_store) {
              let label = item.name;
              if (item.distance) {
                label = `${item.name} (${item.distance.toFixed(2)}km)`;
              }
              return {
                id: item.id,
                label: label,
                code: item.number,
                latitude: item.latitude,
                longitude: item.longitude,
                city: item.city,
                address: item.address,
                zipcode: item.zipcode,
                email: item.email,
                phone: item.phone
              };
            }
          });
          if (!zipcode && !city) {
            this.storeOptionsBackup = this.storeOptions;
          }
        })
        .catch(() => (this.storeNotFound = true));
      // Uncomment backup for static store options
      // this.storeOptions = this.storeOptions.map(item => {
      //   return {
      //     label: item.name + " - " + item.address,
      //     code: item.number,
      //     latitude: item.latitude,
      //     longitude: item.longitude,
      //     city: item.city,
      //     address: item.address,
      //     zipcode: item.zipcode,
      //     email: item.email,
      //     phone: item.phone
      //   };
      // });
      // this.storeOptionsBackup = this.storeOptions;
    },
    determineNextStep() {
      // Uncomment for show suppliers select
      this.getStores();
      if (this.claim.is_routed) {
        if (
          this.claim.on_hold_code === "" ||
          this.claim.on_hold_code === "FOUTIEVE REPARATIE"
        ) {
          this.accepted = true;
          this.nextStepText = this.claim.customer_notification;
          if (this.claim.location_in_template) {
            this.storeProvided = false;
            this.getStores();
          } else if (this.claim.store_no) {
            this.storeProvided = true;
            this.getStore();
          }
        } else {
          this.rejected = true;
        }
      }
    },
    save() {
      this.form_states.form = null;
      this.form_states.loading = true;

      const payload = {
        claim_type: "1",
        contract: this.step2.contract.id,
        product_id: this.step2.product,
        type: this.step3.type,
        date: this.step4.date,
        areas: this.step5.areas.join(", "),
        culprit_question: this.$i18n.t("form.report_damage_culprit.label"),
        culprit: this.step6.culprit,
        cause_question: this.$i18n.t("form.report_damage_cause.label"),
        cause: this.step6.cause,
        location_question: this.$i18n.t("form.report_damage_location.label"),
        location: this.step6.location,
        discovery_question: this.$i18n.t("form.report_damage_discovery.label"),
        discovery: this.step6.discovery,
        origin_question: this.$i18n.t("form.report_damage_origin.label"),
        origin: this.step6.origin,
        reclaim_question: this.$i18n.t("form.report_damage_origin.label"),
        reclaim: this.step7.selected,
        claim_unique: uuid.v4()
      };
      if ("description" in this.step5) {
        payload["description"] = this.step5.description;
      }

      // if ("screen_damage_type" in this.step4) {
      //   if (payload["description"] != undefined) {
      //     payload["description"] += " - " + this.step4.screen_damage_type;
      //   } else {
      //     payload["description"] = this.step4.screen_damage_type;
      //   }
      // }

      let formData = new FormData();
      if (this.step5.files && this.step5.files.length) {
        this.step5.files.forEach((f, i) => {
          formData.append(`file-${i}`, f);
        });
      }

      formData.append("data", JSON.stringify(payload));
      this.$store
        .dispatch("claims/create", formData)
        .then(result => {
          this.claim_number = result.data.value;
          this.form_states.form = true;
          this.form_states.loading = false;
          this.$router.push({
            name: "processing",
            query: {
              claim_type: "1",
              category: this.step2.contract.category_code
            }
          });
        })
        .catch(() => {
          this.form_states.form = false;
          this.form_states.loading = false;
        });
    },
    updateClaimWithStore() {
      const storePayload = {
        claim_id: this.claim.id,
        store_no: this.selectedStore
      };

      this.storeLoading = true;

      this.$store
        .dispatch("claims/updateClaimStore", storePayload)
        .then(() => {
          this.storeLoading = false;
          this.determineNextStep();
        })
        .catch(() => {
          this.storeLoading = false;
        });
    },
    routedClaimConfiguration() {
      const _category = ClaimConfiguration.objectCategories.find(
        objectCategory => objectCategory.name === this.$route.query.category
      );

      this.continueSteps = _category.nextSteps["damage"];
    }
  },
  mounted() {
    this.$refs.step1.setCollapsed(false);
    this.$store.dispatch("contracts/getAll");
  },
  created() {
    if (this.$route.query.routed) {
      this.form_states.form = true;
      this.routedClaimConfiguration();
      this.determineNextStep();
    }
    // Uncomment for show suppliers select
    // this.determineNextStep();
  }
};
</script>

<style lang="scss">
@import "../../theme/variables";

.damage-report-success-card-steps {
  background-color: $color-purple-lighter;
  list-style: none;
  counter-reset: li;
}

.damage-report-success-card-steps li::before {
  content: counter(li);
  color: $color-primary;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.damage-report-success-card-steps li {
  counter-increment: li;
}

.damage-report-success-card-step {
  display: inline-grid;
  padding-left: 1em;
  padding-right: 1em;
}
</style>
