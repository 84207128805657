<template>
  <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
    <n-form-textarea
      :label="`${$t('form.report_damage_culprit.label')}*`"
      id="input-description-culprit"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="culprit"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
      class="sm:text-xs text-base"
    />
    <n-form-textarea
      :label="`${$t('form.report_damage_cause.label')}*`"
      id="input-description-cause"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="cause"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
      class="sm:text-xs text-base"
    />
    <n-form-textarea
      :label="`${$t('form.report_damage_location.label')}*`"
      id="input-description-location"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="location"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
      class="sm:text-xs text-base"
    />
    <n-form-textarea
      :label="`${$t('form.report_damage_discovery.label')}*`"
      id="input-description-discovery"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="discovery"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
      class="sm:text-xs text-base"
    />
    <n-form-textarea
      :label="`${$t('form.report_damage_origin.label')}*`"
      id="input-description-origin"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="origin"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
      class="sm:text-xs text-base"
    />
  </div>
</template>

<script>
export default {
  name: "components.report.damage.step-date",
  data: () => ({
    culprit: "",
    cause: "",
    location: "",
    discovery: "",
    origin: ""
  }),
  computed: {
    dataValid() {
      return (
        !!this.culprit &&
        !!this.cause &&
        !!this.location &&
        !!this.discovery &&
        !!this.origin
      );
    }
  },
  methods: {
    persistData() {
      this.$parent.$parent.$emit("dataChange", {
        culprit: this.culprit,
        cause: this.cause,
        location: this.location,
        discovery: this.discovery,
        origin: this.origin
      });
      this.$parent.$parent.$emit("pickedChange", "");
    },
    resetData() {
      this.$parent.$parent.$emit("dataChange", {});
      this.$parent.$parent.$emit("pickedChange", "");
    }
  },
  watch: {
    culprit() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    cause() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    location() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    discovery() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    origin() {
      if (this.dataValid) {
        this.persistData();
      }
    }
  }
};
</script>
